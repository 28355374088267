import React from "react";


export default function JobDescription(props) {
  const {Name,Position, Description, Link, Dates, List} = props.JobInfo;
  const listLines = List.map((item) =>
      <li>{item}</li>)
      return (

        <div className="component-container depth-three" >
          <h1 className="primary-text-size-c primary-text" style={{fontSize:'30px'}}>{Position}{' '}
            <a href={Link} target="_blank" rel="noopener noreferrer" className="contrast-text-secondary">
            @{Name}
            </a>
          </h1>
          <h3 className="tertiary-text-size-c tertiary-text" style={{fontSize:'16px'}}>{Dates}</h3>
          <h2 className="secondary-text-size-c secondary-text" style={{fontSize:'20px'}} >{Description}</h2>
          <ul className="secondary-text-size-c secondary-text" style ={{listStyleType: 'circle', fontSize:'18px'}}>
            {listLines}
          </ul>
        </div>

      );
}