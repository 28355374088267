import React, { Fragment } from "react";
import {useViewportScroll} from "framer-motion";
import './styles.css';
import "./misc/fonts/ubuntu/Ubuntu-R.ttf"
import "./misc/fonts/digital/DS-DIGI.TTF"
import 'react-typist/dist/Typist.css'

// local Imports
import Home from './pages/home';
import About from './pages/about';
import Experience from './pages/experience';
import Projects from './pages/work';
import Contact from './pages/contact';
import Nav from './components/nav';
import Footer from './components/footer';

function App() {
  const {scrollYProgress} = useViewportScroll();
  console.log(scrollYProgress);
  return (
    <Fragment>
      <Nav scrollPosition = {scrollYProgress} />
      <Home id = 'home'/>
      <About id='about'/>
      <Experience id='exp'/>
      <Projects id ='proj'/>
      <Contact id='contact'/>
      <Footer/>
    </Fragment>
  );
}

export default App;
