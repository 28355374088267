import React from 'react';

export default function Contact({id}) {
  return (

    <div className="page-container" id={id}style={{flexDirection:'column', alignItems:'center'}}>
      <h1 className="title primary-text"><span className="index contrast-text-secondary">04. </span>Lets Work Together</h1>
      <h2 className="sub-title primary-text">Although I'm a full-time student..</h2>
      <h2 className="sub-title primary-text">I'm always looking for:</h2>
      <ul className="main-text secondary-text">
        <li>Internship Opportunities</li>
        <li>Contract Work</li>
        <li>Research Opportunities</li>
        <li>Cool projects to be part of</li>
        <li>Possibly part time work</li>
      </ul>
      <a className="button" href="mailto:alexvzvz28@gmail.com?Subject=Hello"> Contact Me</a> 
      <a className="button" href="mailto:alexvzvz28@gmail.com?Subject=I want your resume"> Request Resume</a> 
    </div>
  );
}