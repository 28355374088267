import React from "react";
import {
    Route,
    NavLink,
    HashRouter
  } from "react-router-dom";

// local Imports
import JobDescription from './jobDescription';
import strings from '../res/string';


class Jobs extends React.Component {
    render() {
      return (
          <HashRouter>
          <div className="row-container-phone row-container" style={{justifyContent:"space-between"}}>
            <div className="Job-index-container">
                <ul className="primary-text-size-c secondary-text list-jobs" style={{fontSize:'20px'}}>
                    <li className="job-link" ><NavLink 
                        exact 
                        to="/"  
                        className="job-link" 
                        activeClass="active" 
                        >
                            Microsoft
                            </NavLink></li>
                    <li className="job-link"  ><NavLink
                     to="/BeyondFluency"
                     className="job-link" 
                     activeClass="active"
                       >BeyondFluency</NavLink></li>
                    <li className="job-link" ><NavLink
                     to="/Inetco"
                     className="job-link" 
                     activeClass="active"
                       >Inetco</NavLink></li>
                    <li className="job-link" ><NavLink
                     to="/Orbit"
                     className="job-link" 
                     activeClass="active"
                       >Orbit</NavLink></li>
                </ul>
            </div>
            <div className="job-description-container">
                <Route 
                    exact path="/" 
                    render={(props) => 
                        <JobDescription JobInfo={strings.Experience.exp1}/>
                    }
                />
                <Route path="/BeyondFluency"  
                    render={(props) => 
                        <JobDescription JobInfo={strings.Experience.exp2}/>
                    }
                />
                <Route path="/Inetco"  
                    render={(props) => 
                        <JobDescription JobInfo={strings.Experience.exp3}/>
                    }
                />
                <Route path="/Orbit"  
                    render={(props) => 
                        <JobDescription JobInfo={strings.Experience.exp4}/>
                    }
                />
            </div>
          </div>
          </HashRouter>
      );
    }
  }
   
  export default Jobs;