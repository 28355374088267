import React from 'react';
import {FaLongArrowAltDown} from "react-icons/fa"
import Typist from 'react-typist';
import { Link } from "react-scroll";


export default function Home(props) {
  const {scrollYProgress, id} = props;
  console.log("error",scrollYProgress)
  return (
    <div className="page-container" id={id}>
      <div className="flex-center-vertically" >
        <Typist
          startDelay={1500}
          avgTypingDelay={100}
          cursor = {{
            show: true,
            blink: true,
            element: '|',
            hideWhenDone: false,
            hideWhenDoneDelay: 1000,
          }}
          >
          <h1 className="name primary-text">Hello!</h1>
          <h1 className="name primary-text">I'm Alex</h1>
        </Typist>
          {/* On scroll down expand cursor line */}
        <Typist
          startDelay={4000}
          avgTypingDelay={20}
          cursor= {{show: false}}>
          <h2 className="sub-title secondary-text">An Electrical and Computer Engineering Student 
          skilled in CyberSecurity, WebDev and DevOps.</h2>
          </Typist>
      </div>
    <div className="home-arrow">
      <Link
        to='about'
        spy={true}
        smooth={true}
        duration={500}>
        <FaLongArrowAltDown className="primary-text" style={{fontSize:'40px'}} /> 
      </Link>
    </div>
    </div>
  );
}