import React from 'react';
import { FaGithub } from "react-icons/fa";
import { IoMdOpen } from "react-icons/io";


export default function Project(props){
    const {Name,ShortDescription, Links, Type, Tech} = props.ProjectDescription;
    const Technologies = Tech.map((item) =>
        <li className="technologies">{item}</li>)
    return(
            <div className='projectInfo-container depth-three'>
                <h3 className="hint-text secondary-text-size-c ">{Type}</h3>
                <h1 className="primary-text primary-text-size-c">{Name}</h1>
                <p className="secondary-text-size-c secondary-text">{ShortDescription}</p>
                <div className='row-container tertiary-text secondary-text-size-c'>
                    <ul className="list-row">{Technologies}</ul>
                    <ul>
                        <a href={Links.Github} target="_blank" rel="noopener noreferrer">
                        <FaGithub className="icon-proj" target="_blank" />
                        </a>
                        <a href={Links.Website} target="_blank" rel="noopener noreferrer">
                        <IoMdOpen className="icon-proj"/>
                        </a>
                    </ul>
                </div>
            </div>
    );
}