import * as React from "react";
import {
  motion,
  useViewportScroll,
  useSpring,
  useTransform
} from "framer-motion";
// Local Imports
import Logo1 from "../misc/img/VZ-logo.png"
import { Link } from "react-scroll";
import { FaLinkedinIn, FaGithub, FaEnvelope } from "react-icons/fa";


export default function Nav() {
  const { scrollYProgress } = useViewportScroll();
  const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 1]);
  const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });

  return (
    <React.Fragment style={{zindex:'3'}}>
      <Link 
            to="home"
            spy={true}
            smooth={true}
            duration={500}>
              <img 
        src={Logo1} 
        alt="Website logo" 
        className="logo" 
        ></img>
            </Link>
      <svg className="progress-icon" viewBox="0 0 70 70">
        <motion.path
          fill="none"
          strokeWidth="3" 
          stroke="#cac5c5"
          strokeDasharray="0 1"
          d="M 0 0 L 10 0 L 10 38  L 10 0 L 25 0 L 25 65 L 25 0 L 40 0 L 40 36 L 40 0 L 55 0 L 55 50 L 55 0 L 65 0"
          style={{
            pathLength,
            rotate: 90,
            translateX: 5,
            translateY: 5,
            scaleX: -1 // Reverse direction of line animation
          }}
        />
      </svg>
      <ul className="nav-container">
        <li className="nav-link" >
          <Link 
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            duration={500}>
              About
            </Link>
        </li>
        <li className="nav-link" >
          <Link 
            activeClass="active"
            to="exp"
            spy={true}
            smooth={true}
            duration={500}>
              Experience
            </Link>
        </li>
        <li className="nav-link" >
          <Link 
            activeClass="active"
            to="proj"
            spy={true}
            smooth={true}
            duration={500}>
              Work
            </Link>
        </li>
        <li className="nav-link" >
          <Link 
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            duration={500}>
              Contact
            </Link>
        </li>
      </ul>
      <div className="icon-container">
        <a href="https://www.linkedin.com/in/alex-vazquez" target="_blank" rel="noopener noreferrer">
        <FaLinkedinIn className="icon" target="_blank" />
        </a>
        <a href="https://github.com/Alexvz2" target="_blank" rel="noopener noreferrer">
        <FaGithub className="icon"/>
        </a>
        <a href="mailto:alexvzvz28@gmail.com?Subject=Hello">
        <FaEnvelope className="icon"/>
        </a>
      </div>
    </React.Fragment>
  );
};