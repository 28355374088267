import React from "react";

// Local Imports
import Feed from '../components/projectsFeed'

export default function Projects({id}) {
  return (
    <div className="work-order page-container-expand" id={id}>
      <div>
        <h1 className="title primary-text"><span className="index contrast-text-secondary">03. </span>Work</h1>
        <h2 className="sub-title secondary-text">
          Some Cool Stuff I've Worked On Recently
        </h2>
      </div>
      <div className="column-container">
        <Feed/>
      </div>
    </div>
  );
}