const strings = {
    Home:{

    },
    About:{

    },
    Experience:{
        quote:`“Pure logical thinking cannot yield us any knowledge of the empirical world; all knowledge of reality starts from experience and ends in it.”
        Einstein, Albert.`,
        exp1:{
            Name:"Microsoft",
            Position:"ODSP Software Engineer(Explore)",
            Link:"https://www.microsoft.com/",
            Dates:" May 2019 - August 2019",
            Description:`As a SWE at Microsoft I worked on OneDrive for Business, using Reactjs and TypeScript, where I:`,
            List:
                [`Implemented a new sharing functionality using the sharing permissions API, which underwent into production.`,
                `Created project considering the customers need and its business impact`,
                `Authored a new authentication method for file selection.`]
        },
        exp2:{
            Name:"BeyondFluency",
            Position:"Backend",
            Link:"https://www.beyondfluency.ca/",
            Dates:"May-Aug, 2019",
            Description:`Using MYSQL, NodeJS and ReactJS developed the back-end of BeyondFluency Public and Admin website,
            given the responsibilities of:`,
            List:[
                `Implementing the payment logic using Stripe API.`,
                `Developing RESTful Api’s for the logic on courses registration, course creation, credits purchase, etc, using AWS
                SQL RDB.`,
                `Authenticating users with JW Tokens and automating Email services using NodeMailer and CronJobs`],
        },
        exp3:{
            Name:"INETCO Systems ltd.",
            Position:"DevOps Intern",
            Link:"https://www.inetco.com/",
            Dates:"May 2018 - September 2018",
            Description:'Using the Agile development methodology did the following:',
            List:[
                `Successfully migrated batch automated build testing scripts to Python, increasing its configurability and improving
                its functionality.`,
                `Authored a Slack-Bot in order to control, automate and show results of builds testing, reducing the testing time`,
                `Developed analytical custom dashboards using Java, SQL and JS on Jaspersoft Studio for NCR, which output data
                in half the time previous dashboards did.`,
                `Implemented the installers ability to automatically detect host OS environment, simplifying the installation
                process.`,
                `Created an Impala testing server on Redhat, using unix and SQL.`,
            ]
        },
        exp4:{
            Name:"UBC Orbit",
            Position:"Senior Controls Engineer",
            Link:"https://www.ubcorbit.com/",
            Dates:"Aug 2017 - Present",
            Description:`Senior Member of the UBC Engineering design team UBC Orbit, with the objective to build a fully automated
            satellite. Part of the Attitude Determination and Control Systems(ADCS) division, where I:`,
            List:[
                `Implemented the Kalman Filter system in C++.`,
                `Implemented a detumbling algorithm using a b-dot and PID controller for the control of U-SAT`,
                `Designed and fabricated custom PCB’s for the ADCS division and its components.`,
            ]
        },
    },
    Projects:[
        {
            Name:`LUUP`,
            Type:`PWA`,
            ShortDescription:`A progressive web app implemented with ReactJs and NodeJs, which determines and suggests a club or bar
            density using google maps api.`,
            LongDescription:`A progressive web app implemented with ReactJs and NodeJs, which determines and suggests a club or bar
            density using google maps api.`,
            Tech:['MapsApi', 'NodeJs', 'ReactJs', 'BlueBeacon', 'FireBase'],
            Technologies:['MapsApi', 'NodeJs', 'ReactJs', 'BlueBeacon', 'FireBase'],
            Links:{
                Github:`https://github.com/Alexvz2`,
                WebSite:`https://github.com/Alexvz2`,
            },
            Pic:``,
        },
        {
            Name:`CubeSat Telemetry Decoder`,
            Type:`Telecommunications`,
            ShortDescription:`For my Elec400s course I created the telemetry decoder for the OrcaSat Cubesat`,
            LongDescription:``,
            Tech:['AX.25', 'GNU-RADIO', 'Python'], //important tehcnologies
            Technologies:['AX.25', 'GNU-RADIO', 'Python'],//same thing but longer
            Links:{
                Github:`https://github.com/Alexvz2/OrcaSatDecoder`,
                WebSite:`https://www.orcasat.ca/`,
            },
            Pic:``,
        },
        {
            Name:`CryptoChat`,
            Type:`Ecryption/Messaging`,
            ShortDescription:`Safe and Secure encrypted messaging app using fingerprints as keys`,
            LongDescription:`Safe and Secure encrypted messaging app using fingerprints as keys`,
            Tech:['Kotlin', 'Python','KeyStore'],
            Technologies:[],
            Links:{
                Github:`https://github.com/Alexvz2/CryptoChat`,
                WebSite:`https://github.com/Alexvz2`,
            },
            Pic:``,
        },
        {
            Name:`Methods for a detumbling algorithm for 2U CubeSat `,
            Type:`Research Paper / IEEE`,
            ShortDescription:`Research paper completed as part of ELEC400s exploring the different control laws used for CubeSat’s detumbling.`,
            LongDescription:`Research paper completed as part of ELEC400s exploring the different control laws used for CubeSat’s detumbling.`,
            Tech:['Matlab', 'Symulink'],
            Technologies:['MatLab', 'Symulink'],
            Links:{
                Github:`https://github.com/Alexvz2`,
                WebSite:`https://www.ieee.org/`,
            },
            Pic:``,
        },
        {
            Name:`BookIt:`,
            Type:`IoT / Traffic`,
            ShortDescription:`Study space availability based off Access Points Mac Addresses and BSSIDS`,
            LongDescription:`Study space availability based off Access Points Mac Addresses and BSSIDS`,
            Tech:['Python', 'RaspberryPi', 'UNIX', 'Aircrack'],
            Technologies:['Python', 'RaspberryPi', 'UNIX', 'Aircrack'],
            Links:{
                Github:`https://github.com/Alexvz2/bookit_vap`,
                WebSite:`https://github.com/Alexvz2/bookit_vap`,
            },
            Pic:``,
        },
        {
            Name:`Copy2Cat`,
            Type:`Private Hackathon / CyberSecurity`,
            ShortDescription:`Copy2Cat, developed an Advanced Persistent Threat (APT) emulator in order to label trends.`,
            LongDescription:`Copy2Cat, developed an Advanced Persistent Threat (APT) emulator in order to label trends.`,
            Tech:['VM', 'Python', 'TensorFlow'],
            Technologies:['VM', 'Python', 'TensorFlow'],
            Links:{
                Github:`https://news.microsoft.com/life/one-week-microsoftlife/`,
                WebSite:`https://news.microsoft.com/life/one-week-microsoftlife/`,
            },
            Pic:``,
        },
        {
            Name:`Slack2Jenkins`,
            Type:`Bot / Automation`,
            ShortDescription:`A Slack bot witht he functionality to notify and control Jenkins builds.`,
            LongDescription:`A Slack bot witht he functionality to notify and control Jenkins builds.`,
            Tech:['SlackAPI', 'Python', 'Jenkins'],
            Technologies:['SlackAPI', 'Python', 'Jenkins'],
            Links:{
                Github:`https://github.com/Alexvz2/Slack-to-Jenkins-Bot`,
                WebSite:``,
            },
            Pic:``,
        },
        {
            Name:`Small Steps `,
            Type:`Hackathon/ IoT / NWA`,
            ShortDescription:`Cross Platform Mental Health Smart Phone Application, Tool for PTSD Therapists`,
            LongDescription:`Cross Platform Mental Health Smart Phone Application, Tool for PTSD Therapists.`,
            Tech:['ReactNative', 'Firebase', 'FitBit'],
            Technologies:['ReactNative', 'Firebase', 'FitBit'],
            Links:{
                Github:`https://github.com/Alexvz2/small_steps`,
                WebSite:``,
            },
            Pic:``,
        },
    ],
    Skills:{

    },
    Contact:{

    }
}

export default strings