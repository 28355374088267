import React, { Fragment } from 'react';
// Local Imports
import Project from './projects';
import strings from '../res/string';

export default class Feed extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        items: strings.Projects,
        visible: 4,
        error: false
      };
  
      this.loadMore = this.loadMore.bind(this);
    }
  
    loadMore() {
      this.setState((prev) => {
        return {visible: prev.visible + 2};
      });
    }
  
  
    render() {
      return (
        <Fragment>
          <div className="projects-container">
            {this.state.items.slice(0, this.state.visible).map((item, index) => {
                return (
                  <Project ProjectDescription={item}/>
                );
              })}
            </div>
            {this.state.visible < this.state.items.length &&
               <button onClick={this.loadMore} className= "button"  style={{alignSelf:'center'}}type="button">Load more</button>
            }
          </Fragment>
      );
    }
  }
  