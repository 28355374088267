import React from 'react';
import aboutPic from '../misc/img/me.JPG';


export default function About({id}) {
  return (
    <div className=" page-container-mobile page-container" style={{flexDirection:'column'}} id ={id}>
      <h1 className="title primary-text"><span className="index contrast-text-secondary">01. </span>About Me</h1>
      <div className="row-container">
        <div className="container-left">
          <h2 className="main-text secondary-text">
          Hi! I'm Alex,
          a third year Electrical and Computer Engineering Student at the
          <a className="university" href="https://www.ubc.ca/"target="_blank" rel="noopener noreferrer" 
          > University of British Columbia </a>based in Vancouver, Canada.</h2>
          <h2 className="main-text secondary-text">
            I enjoy solving problems with innovative and analytical approaches.
            I've developed projects ranging from satellite controllers and decoders to RESTful API's and CyberSecurity Tools.
          </h2>
          <h2 className="main-text secondary-text">
            Technologies I am experienced with:
          </h2>
          </div>  
          <div className="container-right">
            <img src={aboutPic} alt="me pic" className="img"/>
            <p className='hint-text tertiary-text' style={{marginLeft:'15%'}}> Image Generated using artistic ML</p>
          </div>
      </div>

      <div className=" row-container-phone row-container contrast-text-primary sub-title">
        <div>
          <ul>
            <li>Python3<span className="counter"><span className="contrast-text-tertiary">0</span>1001</span></li>
            <li>JS(React, Node, Express)<span className="counter">&nbsp;&nbsp;&nbsp;<span className="contrast-text-tertiary">0</span>1000</span></li>
            <li>C++/C<span className="counter"><span className="contrast-text-tertiary">0</span>110</span></li>
            <li>SQL<span className="counter"><span className="contrast-text-tertiary">0</span>1000</span></li>
            <li>UNIX/BASH<span className="counter"><span className="contrast-text-tertiary">00</span>111</span></li>
            <li>Kotlin<span className="counter" ><span className="contrast-text-tertiary">00</span>101</span></li>
          </ul>
        </div>
        <div  className='second-skills'>
          <ul>
            <li>Database(AWS/Firebase/Azure)<span className="counter">&nbsp;&nbsp;&nbsp;<span className="contrast-text-tertiary">0</span>1000</span></li>
            <li>WebDev<span className="counter"><span className="contrast-text-tertiary">0</span>1001</span></li>
            <li>DevOps<span className="counter"><span className="contrast-text-tertiary">0</span>1001</span></li>
            <li>CyberSecurity<span className="counter" > <span className="contrast-text-tertiary">00</span>111</span></li>
            <li>Controllers(Matlab + Simulink)<span className="counter" ><span className="contrast-text-tertiary">00</span>111</span></li>
            <li>Assembly<span className="counter"><span className="contrast-text-tertiary">0</span>1000</span></li>
          </ul>
        </div>
      </div>
    </div>
  );
}


