import React from 'react';
import Jobs from '../components/jobs';
import strings from '../res/string';


export default function JobDescription({id}) {
  return (
    <div className="page-container-mobile page-container" id={id} style={{flexDirection:'column', justifyContent: 'space-between' }}>
      <h1 className="title primary-text"><span className="index contrast-text-secondary">02. </span>Experience</h1>
      <Jobs style={{zIndex:'3'}}/>
      <h2 className="quote main-text hint-text" >
        {strings.Experience.quote}
        </h2>
    </div>
  );
}